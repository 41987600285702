/* --- RESPONSIVE --- */
@media (min-width: 640px) {
  :root {
    --header-height: 113px;
  }

  .heading {
    font-size: 2.5rem;
  }

  .text-lg {
    font-size: 1.5rem;
  }

  .button {
    font-size: 1rem;
    padding: 22px 38px;
  }

  .hero {
    padding-top: calc(19.2vh + var(--header-height));
    padding-bottom: 18.1vh;

    &__inner {
      font-size: 2.5rem;
    }
  }

  .about-us {
    padding-top: 135px;
    padding-bottom: 135px;
  }

  .education {
    &__slider {
      margin-top: 130px;
    }
  }

  .logo {
    max-width: 91px;
  }
}

@media (min-width: 768px) {
  .reviews {
    &__item {
      //pointer-events: none;
    }

    &__col {
      &:first-child {
        align-items: flex-end;
      }
    }
  }
}

@media (min-width: 992px) {
  .button-record {
    width: 154px;
    height: 154px;
    font-size: 1rem;
  }

  .mobile-menu-active {
    .header {
      &-nav {
        justify-content: flex-start;
        padding-top: 25vh;
      }
    }
  }

  .prices-section {
    &__image {
      display: block;
    }
  }

  .footer {
    &__col {
      margin-bottom: 0;
    }

    &__inner {
      flex-direction: row;
    }
  }
}

@media (min-width: 1200px) {
  .mobile-menu-active {
    .header {
      &-nav {
        padding-top: 0;
      }
    }
  }

  .header {
    padding-top: 39px;
    padding-bottom: 39px;

    &__logo {
      position: static;
    }

    &__menu-button {
      display: none;
    }

    &-nav {
      width: auto;
      height: auto;
      flex-direction: row;

      position: static;
      background: none;

      opacity: 1 !important;
      visibility: visible !important;
      line-height: 1.219em;

      &__item::before,
      &__item::after {
        display: none;
      }
    }
  }

  .education {
    &__inner {
      flex-direction: row;
    }

    &__slider {
      order: 0;
    }

    &__text {
      width: 45%;
    }
  }

  .reviews {
    &__inner {
      flex-direction: row;
      align-items: flex-start;
    }
  }
}
/* --- /RESPONSIVE --- */