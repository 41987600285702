@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  outline: 0;
}

*:focus {
  outline: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0;
  margin-left: 0;
}

blockquote {
  margin: 0;
}

b,
strong {
  font-weight: bolder;
}

details {
  display: block;
}

small {
  font-size: 80%;
  font-weight: 400;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  background-color: transparent;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 0;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }
}
/*! --- COMMON --- */
/* --- GRID SYSTEM --- */
.container,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1390px;
  }
}
/* --- /GRID SYSTEM --- */
:root {
  --header-height: 90px;
}

::selection {
  color: #FFFFFF;
  background-color: #000000;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 20px;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #000000;
  font-size: 1rem;
  line-height: 1.219em;
  font-family: "Montserrat", sans-serif;
}

.page-content {
  overflow: hidden;
  position: relative;
}

.page-wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: relative;
}

a {
  display: inline-flex;
  color: #A40101;
  transition: color 0.15s ease;
}
a:hover {
  color: #e10101;
  text-decoration: none;
}
a:active {
  color: #fe2222;
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
button:focus {
  outline: none;
}

picture {
  display: inline-block;
  overflow: hidden;
}
picture > * {
  width: 100%;
  display: block;
  object-fit: cover;
}

input:focus {
  outline: none;
}

button, input, optgroup, select, textarea {
  line-height: 1.3;
}

/* --- ICONS --- */
.icon {
  transition: transform 0.35s ease;
}
.icon:hover {
  transform: scale(1.05);
}

.instagram-icon {
  display: inline-block;
  flex-shrink: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 44px;
  height: 44px;
  background-image: url("../images/instagram.svg");
}

.arrow {
  display: inline-block;
  flex-shrink: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 13px;
  height: 13px;
}
.arrow--left {
  background-image: url("../images/chevron-down.svg");
  transform: rotate(90deg);
}
.arrow--right {
  background-image: url("../images/chevron-down.svg");
  transform: rotate(-90deg);
}

/* --- /ICONS --- */
/* --- BUTTONS --- */
.menu-button {
  --button-color: #000000;
  width: 32px;
  height: 18px;
  padding: 0;
  display: inline-flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-shrink: 0;
  position: relative;
  box-sizing: content-box;
}
.menu-button i {
  height: 2px;
  display: block;
  margin-bottom: 6px;
  background-color: var(--button-color);
  transition: all 0.2s ease;
}
.menu-button i:nth-of-type(1) {
  width: 100%;
}
.menu-button i:nth-of-type(2) {
  width: 80%;
}
.menu-button i:nth-of-type(3) {
  width: 100%;
  margin-bottom: 0;
}
.menu-button:hover i {
  width: 100%;
}
.menu-button.menu-button--active i {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #A40101;
}
.menu-button.menu-button--active i:nth-of-type(1) {
  width: 100%;
  transform: rotate(45deg);
}
.menu-button.menu-button--active i:nth-of-type(2) {
  width: 100%;
  transform: rotate(-45deg);
}
.menu-button.menu-button--active i:nth-of-type(3) {
  width: 0;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  user-select: none;
  color: inherit;
  line-height: 1.3;
  padding: 3.45vw 5.95vw;
  color: #FFFFFF;
  text-decoration: none;
  font-size: 3.13vw;
  background: #000000;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.34);
  border-radius: 25px;
  transition: background-color 0.15s ease, box-shadow 0.15s ease;
}
.button:hover {
  background-color: #A40101;
  color: #FFFFFF;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.34);
}

.arrow-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  user-select: none;
  color: inherit;
  line-height: 1.3;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #000000;
  transition: transform 0.35s ease;
}
.arrow-button::before, .arrow-button::after {
  display: none;
}
.arrow-button:hover {
  transform: scale(1.08);
}

.button-record {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  user-select: none;
  color: inherit;
  line-height: 1.3;
  width: 100px;
  height: 100px;
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 100;
  background: #FFFFFF;
  border-radius: 50%;
  border: 4px solid #FB0000;
  font-size: 0.7em;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
}

/* --- /BUTTONS --- */
/* --- UTILS --- */
.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.unselectable {
  pointer-events: none;
  user-select: none;
}

.visually-hidden {
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  clip: rect(0 0 0 0) !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.link-absolute {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.image-responsive-wrapper {
  position: relative;
  overflow: hidden;
}

.image-responsive {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
}

/* --- /UTILS --- */
/*--- COLLAPSE ---*/
[data-toggle=collapse] {
  cursor: pointer;
}

.collapse {
  max-height: 0;
  visibility: hidden;
  position: relative;
  overflow: hidden;
  transition: all 0.35s ease;
}

.collapse.show {
  max-height: 100%;
  visibility: visible;
  height: auto;
}

/*--- /COLLAPSE ---*/
/* --- BLOCKS --- */
.text-lg, .prices-table .price {
  font-size: 4.687vw;
  line-height: 1.219em;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.text__button {
  margin-top: 60px;
}
.text p:last-of-type {
  margin-bottom: 0;
}

.heading {
  font-size: 7.8vw;
  line-height: 1.219em;
  font-weight: 700;
  margin-bottom: 1.2em;
}

.logo {
  max-width: 90px;
  display: inline-flex;
}
.logo img {
  width: 100%;
  height: 100%;
}
.logo--lg {
  max-width: 645px;
}

.header {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  top: 0;
  z-index: 999;
  user-select: none;
  background-color: #FFFFFF;
  box-shadow: 0 1px 10px 0 #00000012;
}
.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__menu-button {
  margin-left: 25px;
}
.header__buttons-wrap {
  display: flex;
  align-items: center;
}
.header__logo {
  position: relative;
  bottom: 3px;
}
.header-nav {
  width: 100vw;
  height: 100vh;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  line-height: 2.7em;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  background: #fffcf9;
  transition: opacity 0.12s cubic-bezier(0.68, 0.01, 0.19, 0.99);
}
.header-nav__item {
  padding-left: 20px;
  padding-right: 20px;
  color: inherit;
  text-decoration: none;
  position: relative;
}
.header-nav__item::before, .header-nav__item::after {
  content: "";
  width: 30px;
  height: 2px;
  position: absolute;
  top: 50%;
  opacity: 0;
  background-color: #A40101;
  transition: all 0.35s ease;
}
.header-nav__item::before {
  left: -250px;
}
.header-nav__item::after {
  right: -250px;
}
.header-nav__item:hover::before, .header-nav__item:hover::after {
  opacity: 1;
}
.header-nav__item:hover::before {
  left: -30px;
}
.header-nav__item:hover::after {
  right: -30px;
}
.header-social {
  display: inline-flex;
}

.hero {
  padding-top: calc(15.7vw + var(--header-height));
  padding-bottom: 15.7vw;
  background-color: #A40101;
  user-select: none;
}
.hero-logo {
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: transform 0.5s ease-in-out;
}
.hero__inner {
  max-width: 1130px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 7.8vw;
  font-weight: 700;
  line-height: 1.219em;
  transition: color 0.5s ease-in-out;
}
.hero__inner span {
  padding-top: 21px;
}
.hero__inner span:first-child {
  padding-right: 25px;
}
.hero__inner span:last-child {
  padding-left: 25px;
}
.hero__inner:hover {
  color: #fff;
}
.hero__inner:hover .hero-logo {
  transform: scale(1.05) !important;
}

.about-us {
  padding-top: 21.094vw;
  padding-bottom: 21.094vw;
}
.about-us__inner {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.about-us__button {
  margin-left: auto;
  margin-right: auto;
}

.prices-section {
  padding-top: 57px;
  padding-bottom: 45px;
  background-color: #A40101;
}
.prices-section__inner {
  display: flex;
  justify-content: space-between;
}
.prices-section__image {
  max-width: 29%;
  display: none;
}
.prices-section__prices-table {
  color: #fff;
}

.prices-table {
  width: 100%;
  font-weight: 600;
}
.prices-table__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5em;
  padding-bottom: 0.3em;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
  transition: border-bottom 0.15s ease;
}
.prices-table__row:last-of-type {
  margin-bottom: 0;
}
.prices-table__row:hover {
  border-bottom: 1px dashed white;
}
.prices-table__col:first-child {
  padding-right: 12.883%;
}
.prices-table .price {
  white-space: nowrap;
}

.education {
  padding-top: 50px;
  padding-bottom: 86px;
}
.education__inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.education__slider {
  order: 1;
  width: 100%;
  max-width: 580px;
  margin-top: 20.3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.education__text {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.reviews {
  padding-top: 38px;
  padding-bottom: 50px;
  background-color: rgba(164, 1, 1, 0.83);
  user-select: none;
}
.reviews__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reviews__col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.reviews__col:first-child {
  padding-right: 20px;
}
.reviews__item {
  display: inline-flex;
  margin-bottom: 50px;
  cursor: pointer;
}
.reviews__item img {
  max-width: 100%;
}

.footer {
  padding-top: 25px;
  padding-bottom: 16px;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.219em;
  font-weight: 700;
}
.footer__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.footer__col {
  margin-bottom: 30px;
}
.footer__contacts a {
  color: inherit;
  text-decoration: none;
}
.footer__contacts a:hover:hover {
  color: #e10101;
  text-decoration: none;
}
.footer__contacts a:hover:active {
  color: #fe2222;
}

/* --- MOBILE MENU --- */
.mobile-menu-active {
  overflow: hidden;
}
.mobile-menu-active .header__menu-button {
  z-index: 111;
}
.mobile-menu-active .header-nav {
  opacity: 1 !important;
  visibility: visible !important;
}

/* --- /MOBILE MENU --- */
/* --- /BLOCKS --- */
/* --- FANCYBOX --- */
[data-fancybox] {
  cursor: pointer;
}

/* --- /FANCYBOX --- */
/* --- SWIPER --- */
.education__slider .swiper {
  max-width: 100%;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  position: static;
}
.education__slider .swiper-slide {
  height: 64.47vw;
}
.education__slider .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
}
.education__slider .swiper-button-next, .education__slider .swiper-button-prev {
  display: none;
}
.education__slider .swiper-thumbs {
  max-width: 100%;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 6px;
  padding-right: 6px;
  overflow: hidden;
}
.education__slider .swiper-thumbs .swiper-slide {
  width: 112px;
  height: 15vw;
  min-height: unset;
  filter: grayscale(1);
  transition: filter 0.15s ease;
}
.education__slider .swiper-thumbs .swiper-slide img {
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}
.education__slider .swiper-thumbs .swiper-slide-thumb-active {
  filter: none;
  border-radius: 15px;
}
@media (min-width: 640px) {
  .education__slider .swiper {
    max-width: 437px;
  }
  .education__slider .swiper-slide {
    width: 437px;
    height: 291px;
  }
  .education__slider .swiper-button-next, .education__slider .swiper-button-prev {
    display: flex;
  }
  .education__slider .swiper-thumbs {
    max-width: 437px;
  }
  .education__slider .swiper-thumbs .swiper-slide {
    height: 84px;
  }
}

/* --- /SWIPER --- */
/* --- RESPONSIVE --- */
@media (min-width: 640px) {
  :root {
    --header-height: 113px;
  }

  .heading {
    font-size: 2.5rem;
  }

  .text-lg, .prices-table .price {
    font-size: 1.5rem;
  }

  .button {
    font-size: 1rem;
    padding: 22px 38px;
  }

  .hero {
    padding-top: calc(19.2vh + var(--header-height));
    padding-bottom: 18.1vh;
  }
  .hero__inner {
    font-size: 2.5rem;
  }

  .about-us {
    padding-top: 135px;
    padding-bottom: 135px;
  }

  .education__slider {
    margin-top: 130px;
  }

  .logo {
    max-width: 91px;
  }
}
@media (min-width: 768px) {
  .reviews__col:first-child {
    align-items: flex-end;
  }
}
@media (min-width: 992px) {
  .button-record {
    width: 154px;
    height: 154px;
    font-size: 1rem;
  }

  .mobile-menu-active .header-nav {
    justify-content: flex-start;
    padding-top: 25vh;
  }

  .prices-section__image {
    display: block;
  }

  .footer__col {
    margin-bottom: 0;
  }
  .footer__inner {
    flex-direction: row;
  }
}
@media (min-width: 1200px) {
  .mobile-menu-active .header-nav {
    padding-top: 0;
  }

  .header {
    padding-top: 39px;
    padding-bottom: 39px;
  }
  .header__logo {
    position: static;
  }
  .header__menu-button {
    display: none;
  }
  .header-nav {
    width: auto;
    height: auto;
    flex-direction: row;
    position: static;
    background: none;
    opacity: 1 !important;
    visibility: visible !important;
    line-height: 1.219em;
  }
  .header-nav__item::before, .header-nav__item::after {
    display: none;
  }

  .education__inner {
    flex-direction: row;
  }
  .education__slider {
    order: 0;
  }
  .education__text {
    width: 45%;
  }

  .reviews__inner {
    flex-direction: row;
    align-items: flex-start;
  }
}
/* --- /RESPONSIVE --- */