/* --- FANCYBOX --- */
[data-fancybox] {
  cursor: pointer;
}
/* --- /FANCYBOX --- */

/* --- SWIPER --- */
.education__slider {
  $slides-border-radius: 15px;

  .swiper {
    max-width: 100%;

    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    position: static;

    &-slide {
      height: 64.47vw;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: $slides-border-radius;
      }
    }

    &-button-next,
    &-button-prev {
      display: none;
    }
  }

  .swiper-thumbs {
    max-width: 100%;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 6px;
    padding-right: 6px;
    overflow: hidden;

    .swiper-slide {
      width: 112px;
      height: 15vw;
      min-height: unset;
      filter: grayscale(1);
      transition: filter $transition-default;

      img {
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
      }

      &-thumb-active {
        filter: none;
        border-radius: $slides-border-radius;
      }
    }
  }

  @media (min-width: 640px) {
    .swiper {
      max-width: 437px;

      &-slide {
        width: 437px;
        height: 291px;
      }

      &-button-next,
      &-button-prev {
        display: flex;
      }
    }

    .swiper-thumbs {
      max-width: 437px;

      .swiper-slide {
        height: 84px;
      }
    }
  }
}
/* --- /SWIPER --- */