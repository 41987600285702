@mixin icon {
    display: inline-block;
    flex-shrink: 0;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

@mixin default-background {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

@mixin button-mixin {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    border: none;
    user-select: none;

    color: inherit;
    line-height: 1.3;
}

@mixin list-unstyled {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0;
}

@mixin icon-hover {
    filter: saturate(1.5);
    box-shadow: 0 0 7px rgba(0, 0, 0, .08);
}

@mixin background-picture {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}