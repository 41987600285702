@import 'mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

/*! --- COMMON --- */
/* --- GRID SYSTEM --- */
.container,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {

  .container {
    max-width: 1390px;
  }
}

/* --- /GRID SYSTEM --- */

:root {
  --header-height: 90px;
}

::selection {
  color: #FFFFFF;
  background-color: #000000;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 20px;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  color: #000000;
  font-size: 1rem;
  line-height: 1.219em;
  font-family: 'Montserrat', sans-serif;
}

.page-content {
  overflow: hidden;
  position: relative;
}

.page-wrap {
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  overflow-x: hidden;
  position: relative;
}

@mixin link-hover-light($link-color) {

  &:hover {
    color: lighten($link-color, $link-change-step);
    text-decoration: none;
  }

  &:active {
    color: lighten($link-color, $link-change-step * 2);
  }
}

@mixin link-hover-dark($link-color) {

  &:hover {
    color: darken($link-color, $link-change-step);
    text-decoration: none;

    &::after {
      background-color: darken($link-color, $link-change-step);
    }
  }

  &:active {
    color: darken($link-color, $link-change-step * 2);
  }
}

a {
  $link-color: $red;

  display: inline-flex;
  color: $link-color;
  transition: color $transition-default;

  @include link-hover-light($link-color);
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

picture {
  display: inline-block;
  overflow: hidden;

  & > * {
    width: 100%;
    display: block;
    object-fit: cover;
  }
}

input:focus {
  outline: none;
}

button, input, optgroup, select, textarea {
  line-height: 1.3;
}

/* --- ICONS --- */
.icon {
  transition: transform $transition-medium;

  &:hover {
    transform: scale(1.05)
  }
}

.instagram-icon {
  @include icon;
  width: 44px;
  height: 44px;
  background-image: url("../images/instagram.svg");
}

.arrow {
  @include icon;
  width: 13px;
  height: 13px;

  &--left {
    background-image: url("../images/chevron-down.svg");
    transform: rotate(90deg);
  }
  &--right {
    background-image: url("../images/chevron-down.svg");
    transform: rotate(-90deg);
  }
}
/* --- /ICONS --- */

/* --- BUTTONS --- */
.menu-button {
  --button-color: #000000;

  width: 32px;
  height: 18px;
  padding: 0;

  display: inline-flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-shrink: 0;

  position: relative;
  box-sizing: content-box;

  i {
    height: 2px;
    display: block;
    margin-bottom: 6px;

    background-color: var(--button-color);
    transition: all .2s ease;

    &:nth-of-type(1) {
      width: 100%;
    }

    &:nth-of-type(2) {
      width: 80%;
    }

    &:nth-of-type(3) {
      width: 100%;
      margin-bottom: 0;
    }
  }

  &:hover i {
    width: 100%;
  }

  &.menu-button--active {

    i {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      background-color: $red;

      &:nth-of-type(1) {
        width: 100%;
        transform: rotate(45deg);
      }

      &:nth-of-type(2) {
        width: 100%;
        transform: rotate(-45deg);
      }

      &:nth-of-type(3) {
        width: 0;
      }
    }
  }
}

.button {
  @include button-mixin;
  padding: 3.45vw 5.95vw;

  color: #FFFFFF;
  text-decoration: none;
  font-size: 3.13vw;

  background: #000000;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.34);
  border-radius: 25px;
  transition: background-color $transition-default,
              box-shadow $transition-default;

  &:hover {
    background-color: $red;
    color: #FFFFFF;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.34);
  }
}

.arrow-button {
  @include button-mixin;
  width: 35px;
  height: 35px;

  border-radius: 50%;
  border: 1px solid #000000;
  transition: transform $transition-medium;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.08);
  }
}

.button-record {
  @include button-mixin;
  width: 100px;
  height: 100px;

  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 100;

  background: #FFFFFF;
  border-radius: 50%;
  border: 4px solid #FB0000;

  font-size: 0.7em;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
}
/* --- /BUTTONS --- */

/* --- UTILS --- */
.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.unselectable {
  pointer-events: none;
  user-select: none;
}

.visually-hidden {
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  clip: rect(0 0 0 0) !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.link-absolute {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.image-responsive-wrapper {
  position: relative;
  overflow: hidden;
}

.image-responsive {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
}
/* --- /UTILS --- */

/*--- COLLAPSE ---*/
[data-toggle="collapse"] {
  cursor: pointer;
}

.collapse {
  max-height: 0;
  visibility: hidden;
  position: relative;
  overflow: hidden;
  transition: all .35s ease;
}

.collapse.show {
  max-height: 100%;
  visibility: visible;
  height: auto;
}
/*--- /COLLAPSE ---*/

/* --- BLOCKS --- */
.text-lg {
  font-size: 4.687vw;
  line-height: 1.219em;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__button {
    margin-top: 60px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

.heading {
  font-size: 7.8vw;
  line-height: 1.219em;
  font-weight: 700;
  margin-bottom: 1.2em;
}

.logo {
  max-width: 90px;
  display: inline-flex;

  img {
    width: 100%;
    height: 100%;
  }

  &--lg {
    max-width: 645px;
  }
}

.header {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  position: fixed;
  top: 0;
  z-index: 999;
  user-select: none;

  background-color: #FFFFFF;
  box-shadow: 0 1px 10px 0 #00000012;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__menu-button {
    margin-left: 25px;
  }

  &__buttons-wrap {
    display: flex;
    align-items: center;
  }

  &__logo {
    position: relative;
    bottom: 3px;
  }

  &-nav {
    width: 100vw;
    height: 100vh;

    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    line-height: 2.7em;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    opacity: 0;
    visibility: hidden;

    background: #fffcf9;
    transition: opacity 0.12s cubic-bezier(0.68, 0.01, 0.19, 0.99);

    &__item {
      padding-left: 20px;
      padding-right: 20px;
      color: inherit;
      text-decoration: none;
      position: relative;

      &::before, &::after {
        content: '';
        width: 30px;
        height: 2px;
        position: absolute;
        top: 50%;
        opacity: 0;
        background-color: $red;
        transition: all $transition-medium;
      }
      &::before {
        left: -250px;
      }
      &::after {
        right: -250px;
      }

      &:hover {
        &::before,
        &::after {
          opacity: 1;
        }

        &::before {
          left: -30px;
        }
        &::after {
          right: -30px;
        }
      }
    }
  }

  &-social {
    display: inline-flex;
  }
}

.hero {
  $block-transition: .5s ease-in-out;

  padding-top: calc(15.7vw + var(--header-height));
  padding-bottom: 15.7vw;
  background-color: $red;
  user-select: none;

  &-logo {
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: transform $block-transition;
  }

  &__inner {
    max-width: 1130px;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 7.8vw;
    font-weight: 700;
    line-height: 1.219em;

    transition: color $block-transition;

    span {
      padding-top: 21px;

      &:first-child {
        padding-right: 25px;
      }
      &:last-child{
        padding-left: 25px;
      }
    }

    &:hover {
      color: #fff;

      .hero-logo {
        transform: scale(1.05) !important;
      }
    }
  }
}

.about-us {
  padding-top: 21.094vw;
  padding-bottom: 21.094vw;

  &__inner {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  &__button {
    margin-left: auto;
    margin-right: auto;
  }
}

.prices-section {
  padding-top: 57px;
  padding-bottom: 45px;

  background-color: $red;

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__image {
    max-width: 29%;
    display: none;
  }

  &__prices-table {
    color: #fff;
  }
}

.prices-table {
  width: 100%;
  font-weight: 600;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5em;
    padding-bottom: 0.3em;

    border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
    transition: border-bottom $transition-default;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      border-bottom: 1px dashed rgba(255, 255, 255, 1);
    }
  }

  &__col {
    &:first-child {
      padding-right: 12.883%;
    }
  }

  .price {
    @extend .text-lg;
    white-space: nowrap;
  }
}

.education {
  padding-top: 50px;
  padding-bottom: 86px;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }

  &__slider {
    order: 1;

    width: 100%;
    max-width: 580px;
    margin-top: 20.3vw;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-left: auto;
    margin-right: auto;
    position: relative;
  }

  &__text {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.reviews {
  padding-top: 38px;
  padding-bottom: 50px;
  background-color: rgba($red, 83%);

  user-select: none;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:first-child {
      padding-right: 20px;
    }
  }

  &__item {
    display: inline-flex;
    margin-bottom: 50px;
    cursor: pointer;

    img {
      max-width: 100%;
    }
  }
}

.footer {
  padding-top: 25px;
  padding-bottom: 16px;
  text-align: center;

  font-size: 1.25rem;
  line-height: 1.219em;
  font-weight: 700;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  &__col {
    margin-bottom: 30px;
  }

  &__contacts {
    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        @include link-hover-light($red)
      }
    }
  }
}

/* --- MOBILE MENU --- */
.mobile-menu-active {
  overflow: hidden;

  .header {
    &__menu-button {
      z-index: 111;
    }

    &-nav {
      opacity: 1 !important;
      visibility: visible !important;
    }
  }
}
/* --- /MOBILE MENU --- */
/* --- /BLOCKS --- */